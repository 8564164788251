button {
  background-color: var(--bg-button);
  border-radius: var(--br-form-field);
  border: none;
  color: var(--color-secondary);
  padding: 10px 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
}

.button-inactive {
  background-color: var(--bg-button-inactive);
  cursor: not-allowed;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

button.mat-calendar-body-cell {
  padding: 0;
}
