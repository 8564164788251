/* You can add global styles to this file, and also import other style files */
@import "styles/reset";
@import "styles/buttons";
@import "styles/form-field";
@import "styles/variables";
@import "styles/font";
@import "styles/titles";
@import "styles/theme";

body {
  background-color: var(--bg-body);
}

.media-container {
  max-width: 760px;
  padding: 30px;
  width: 100%;
}

.scroll-container{
  margin-top: var(--header-height);
}

.flex-placeholder {
  flex: 1;
}

html, body { height: 100%; overflow-y: auto }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}