.title-1 {
  font-weight: 600;
  font-size: clamp(28px, 7vw, 35px);
  line-height: clamp(40px, 10vw, 47px);
  color: var(--color-primary);
  text-align: center;
  margin-top: 20px;
}

.title-2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: var(--color-primary);
}